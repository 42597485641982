/* This file is only for HTML and CSS markdown fixes! Other global styles belong into globals.css. */

html,
body,
head,
#root {
  height: 100%;
  width: 100%;
  padding: 0px;
  margin: 0px;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0px;
}

a {
  color: inherit;
  text-decoration: none;
}